* {
  padding: 0;
  margin: 0;
  
}

body {
  scroll-behavior: smooth;
  background-color: black !important;
}

a {
  text-decoration: none;
  font-family: 'Martel', serif;
  font-family: 'Playfair Display', serif;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
}

h2{
  font-family: 'Martel', serif;
}

p {
  font-family: 'Martel', serif;
  font-family: 'Playfair Display', serif;
  font-family: 'Raleway', sans-serif;
}

